<template>
	<v-container fluid style="max-width: 1200px">
		<h2 class="text-center">
			{{ $t('notifications.notificationsCenter') }}
		</h2>

		<v-row>
			<v-spacer />
			<v-btn icon x-large class="mr-4" @click.stop="callReadAllNotifications()">
				<v-icon color="grey lighten-1">mdi-notification-clear-all</v-icon>
			</v-btn>
		</v-row>

		<v-card rounded="xl">
			<v-list color="transparent" rounded class="pa-0">
				<template v-if="!setupInfo.company.ready">
					<v-list-item class="px-4">
						<v-list-item-content>
							<v-list-item-title>
								{{ $t('notifications.almostThere') }}
							</v-list-item-title>
							<v-progress-linear v-model="setupInfo.company.progress" color="green" height="15">
								<strong>{{ setupInfo.company.progress }}%</strong>
							</v-progress-linear>
						</v-list-item-content>
					</v-list-item>
					<v-list-item class="px-4" :to="{ name: setupInfo.company.nextStep.route }">
						<v-list-item-icon>
							<v-icon>mdi-fast-forward</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-subtitle>
								{{ $t('notifications.justNow') }}
							</v-list-item-subtitle>
							<v-list-item-title>{{ $t('notifications.nextStep') }}: {{ setupInfo.company.nextStep.description }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
				<template v-for="notification in notifications">
					<v-list-item class="px-4" :class="[{ warning: !notification.read }]" :key="notification.id" @click="followNotification(notification)">
						<v-list-item-icon>
							<v-icon>mdi-bell-outline</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-subtitle>
								<time :datetime="notification.timestamp">{{ humanTime(notification) }}</time>
							</v-list-item-subtitle>
							<v-list-item-title>{{ notification.content }}</v-list-item-title>
						</v-list-item-content>
						<v-list-item-action>
							<template v-if="notification.read">
								<v-icon color="grey lighten-1">mdi-email-open-outline</v-icon>
							</template>
							<template v-else>
								<v-btn icon @click.stop="callReadNotification(notification)">
									<v-icon color="grey lighten-1">mdi-read</v-icon>
								</v-btn>
							</template>
						</v-list-item-action>
					</v-list-item>
				</template>
			</v-list>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('notifications.notificationsCenter')
		}
	},
	computed: {
		notificationURL(notification) {
			if (true | notification) {
				return '/settings'
			} else {
				return '/settings'
			}
		},
		...mapGetters({
			setupInfo: 'user/setupInfo',
			notifications: 'notifications/notifications'
		})
	},
	created() {
		this.fetchNotifications()
	},
	methods: {
		humanTime(notification) {
			return this.$moment(notification.timestamp).fromNow()
		},
		followNotification(notification) {
			if (notification.type === 'message' && notification.chatID) {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'MessagesChat', params: { id: notification.chatID } })
				})
			} else if (notification.type === 'offer_change_approved' && notification.offerID) {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'Offer', params: { id: notification.offerID } })
				})
			} else if (notification.type === 'offer_change_refused' && notification.offerID) {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'Offer', params: { id: notification.offerID } })
				})
			} else {
				this.readNotification(notification).then(() => {
					this.$router.push({ name: 'NotificationsCenter' })
				})
			}
		},
		callReadNotification(notification) {
			this.readNotification(notification)
				.then(({ data }) => {
					return data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		callReadAllNotifications() {
			this.readAllNotifications()
				.then(({ data }) => {
					return data
				})
				.catch((error) => {
					console.log(error)
				})
		},
		...mapActions('notifications', ['fetchNotifications', 'readNotification', 'readAllNotifications'])
	}
}
</script>
